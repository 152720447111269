const unkwnown = require('./unknown');

module.exports = {
  ...unkwnown,
  chrome: 'Chrome',
  firefox: 'Firefox',
  edge: '΢�� Edge',
  safari: 'Safari',
  googlebot: '�ȸ�bot',
};
