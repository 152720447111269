const unkwnown = require('./unknown');

module.exports = {
  ...unkwnown,
  win: 'Windows',
  macos: 'macOS',
  ios: 'iOS',
  android: 'Android',
  googlebot: '�ȸ�bot',
};
